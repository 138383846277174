<template>
  <div class="container">
    <div class="row mb-3">
      <div class="col green-header-2-text text-center">Repeat Client Feedback</div>
    </div>

    <!--  -->

    <div class="row">
      <div class="col-md-8 mx-auto">
        <form @submit.prevent="submitFeedback" v-if="!submitted">
          <div class="row mb-3">
            <div class="col">
              <p>
                <label for>
                  <strong>Your email address</strong> (optional)
                </label>
                <input type="email" v-model="form.email" class="form-control" />
              </p>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <p>
                <strong class="green-header-4-text">Question 1</strong>
              </p>
              <p>
                <label for>
                  <strong>Which of the following two statements most closely describes your perspective on the current situation with Covid-19?</strong>
                </label>
              </p>

              <div class="form-check">
                <p>
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="form.current_situation"
                    id="radioA"
                    value="a"
                  />
                  <label
                    class="form-check-label"
                    for="radioA"
                  >I broadly agree with the lockdown policy and I broadly trust that the mainstream media is reporting all the facts.</label>
                </p>
              </div>

              <p>
                <strong>OR</strong>
              </p>

              <div class="form-check">
                <p>
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="form.current_situation"
                    id="radioB"
                    value="b"
                  />
                  <label
                    class="form-check-label"
                    for="radioB"
                  >I broadly disagree with the policy of lockdown and I think the mainstream media are failing to capture important, scientific information that challenges this policy.</label>
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="row mb-3">
            <div class="col">
              <p>
                <strong class="green-header-4-text">Question 2</strong>
              </p>
              <p>
                <label for>
                  <strong>Will you be interested in booking a retreat once the lockdown eases?</strong>
                </label>
                <select v-model="form.interested_in_booking" class="form-control">
                  <option>Please select an option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </p>
            </div>
          </div> -->

          <div class="row mb-3">
            <div class="col">
              <p>
                <strong class="green-header-4-text">Question 2</strong>
              </p>
              <p>
                <label for>
                  <strong>Perhaps surprisingly, we are still receiving multiple enquiries every day asking us when we will be open. We aim to open the UK Retreat at the beginning of July. Will you be interested in booking a retreat this year at our Somerset location?</strong>
                </label>

                <textarea class="form-control" rows="4" v-model="form.interested_in_somerset"></textarea>
              </p>
            </div>
          </div>


          <div class="row mb-3">
            <div class="col">
              <p>
                <strong class="green-header-4-text">Question 3</strong>
              </p>
              <p>
                <label for>
                  <strong>When we re-open our current thinking is that we leave social distancing down to the judgement and discretion of clients who are at Amchara. We are open minded to all perspectives and do you have any comments about this?</strong>
                </label>
                <textarea class="form-control" rows="4" v-model="form.social_distancing_comments"></textarea>
              </p>
            </div>
          </div>
          
          <div class="row mb-3">
            <div class="col">
              <p>
                <strong class="green-header-4-text">Question 4</strong>
              </p>
              <p>
                <label for>
                  <strong>As you know teaching and learning is a very important tenet of Amchara. Would you be interested in recommending that we adapt our on retreat educational talks to provide you with more information around Covid-19 and the immune system in general?</strong>
                </label>
                <select v-model="form.talks_content_change" class="form-control">
                  <option>Please select an option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </p>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <p>
                <strong class="green-header-4-text">Question 5</strong>
              </p>
              <p>
                <label for>
                  <strong>Relating to the above, would you like to see any of this in our weekly newsletters?</strong>
                </label>
                <select v-model="form.newsletter_change" class="form-control">
                  <option>Please select an option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </p>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <p>
                <strong class="green-header-4-text">Question 6</strong>
              </p>
              <p>
                <label for>
                  <strong>There is evidence to suggest that certain supplements, taken in a particular way can boost the immune system. Would you like to learn more about these supplements?</strong>
                </label>
                <select v-model="form.supplements_learn" class="form-control">
                  <option>Please select an option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </p>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <p>
                <strong class="green-header-4-text">Question 7</strong>
              </p>
              <p>
                <label for>
                  <strong>Would you like to learn more about health tests pertaining to the immune system, gut and hormone function and other vital health factors?</strong>
                </label>
                <select v-model="form.interested_in_health_tests" class="form-control">
                  <option>Please select an option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-auto ml-auto">
              <button class="btn btn-primary" type="submit" style="color:white;">Submit Feedback</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!--  -->

    <div class="row" v-if="submitted">
      <div class="col">
        <div class="card border-0 shadow">
          <div class="card-body">Thank you for submitting your enquiry</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitted: false,
      form: {
        email: "",
        current_situation: "",
        interested_in_booking: "",
        social_distancing_comments: "",
        interested_in_somerset: "",
        talks_content_change: "",
        newsletter_change: "",
        supplements_learn: "",
        interested_in_health_tests: ""
      }
    };
  },
  methods: {
    submitFeedback() {
      window.axios.post("/retreat-opening", this.form).then(({ data }) => {
        this.submitted = true;

        this.form = {
          email: "",
          current_situation: "",
          interested_in_booking: "",
          social_distancing_comments: "",
          interested_in_somerset: "",
          talks_content_change: "",
          newsletter_change: "",
          supplements_learn: "",
          interested_in_health_tests: ""
        };
      });
    }
  }
};
</script>

<style>
</style>